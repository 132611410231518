import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Pagination, Resp } from 'src/app/interfaces/response'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ApiService } from 'src/app/services/api.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from '../data.service'
import { HttpClient } from '@angular/common/http'
import { apis } from 'src/environments/environment'
import moment from 'moment'


@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnDestroy, OnInit {
    dataStatus = 'fetching'
    currentDate = new Date()
    baseUrl: any
    mytime: Date = new Date();
    bookingsList: Array<any> = []
    selectedIndex: number
    modalRef: BsModalRef
    pagination: Pagination<any>
    searchKeyword = ''
    selectedStatus = ''
    searchKeyword$: Subject<string> = new Subject<string>()
    searchKeywordSub: any
    modalTitle = ''
    inspectionId: any
    loginLoading = false
    dataForm: FormGroup
    selectedId: any
    d: any
    i: any
    filters = {
        page: 1,
        per_page: 10
    }
    breadCrum = [
        {
            link: '/mentor/inspections',
            value: 'Inspections'
        }
    ]
    loaderOptions: LoaderOptionsObject = ConstantsService.createLoaderConfig(5, 4, 1)


    waiting: {
        search: boolean
        save: boolean
        userStatus: Array<any>
    }

    constructor(
        private ds: DataService,
        private fb: FormBuilder,
        public ui: UIHelpers,
        private ms: BsModalService,
        private alert: IAlertService,
        private http: HttpClient,
        public router: Router,
        private route: ActivatedRoute,
        public cs: ConstantsService,
        private api: ApiService
    ) {
        this.baseUrl = apis.baseUrl
        this.waiting = {
            search: false,
            save: false,
            userStatus: []
        }
        let params: any;
        route.paramMap.subscribe((resp: any) => {
            params = {
                inspectionId: parseInt(resp.get('id'))
            }
            this.inspectionId = parseInt(resp.get('id'))
        })
        this.ds.getBookingList(params).subscribe((resp: any) => {
            this.dataStatus = ''
            this.bookingsList = resp.data
            console.log(resp)
        })

        this.breadCrum.push({
            link: `/mentor/inspections/bookings/${this.inspectionId}`,
            value: 'Bookings'
        })

        this.dataForm = this.fb.group({
            title: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
            description: new FormControl(null, [Validators.required, Validators.maxLength(500)]),
            date: new FormControl(null, [Validators.required]),
            time: new FormControl(null, [Validators.required]),
            maxBookings: new FormControl(null, [Validators.required]),
            address: new FormControl(null, [Validators.required]),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null, [Validators.required]),
            zipCode: new FormControl(null, [Validators.required]),

        })

    }

    ngOnInit(): void {
        // this.searchKeywordSub = this.searchKeyword$
        //     .pipe(debounceTime(1000), distinctUntilChanged())
        //     .subscribe((searchKeyword) => {
        //         this.filters.page = 1
        //         this.search()
        //     })
    }
    cancel() {
        const params = {
            inspectionId: this.inspectionId,
            parallelId: this.selectedId
        }
        this.ds.cancelBooking(params).subscribe((resp) => {
            this.waiting.save = false

            if (resp.success === false) {
                this.alert.error('Cancellation Failed ')
                this.modalRef.hide()
                return
            }

            this.bookingsList.splice(this.selectedIndex, 1)
            this.modalRef.hide()
            this.alert.success('Booking cancelled Successfully!')
        })
    }
    //     setPagination(page: any) {
    //         this.filters.page = page
    //         this.router.navigate(['/admin/mentor'], {
    //             queryParams: this.filters,
    //             replaceUrl: true
    //         })
    //     }

    //     searchKeywordChange(value: string) {
    //         this.searchKeyword$.next(value)
    //     }

    //     search(): void {
    //         this.waiting.search = true
    //         this.ds.getList(this.filters).subscribe((resp: any) => {
    //             this.waiting.search = false
    //             if (resp.success === true) {
    //                 this.mentorList = resp.data.data || []
    //                 this.pagination = resp.data as Pagination<any>
    //                 this.dataStatus = 'done'
    //             }
    //         })
    //     }

    openModal(formModal: any, id: number, index: number) {
        this.modalTitle = 'Add New Inspection'
        //this.dataForm.controls.status.setValue('Active')
        this.selectedIndex = index
        if (index > -1) {
            this.modalTitle = 'Edit Mentor'
            this.selectedId = id
            this.selectedIndex = index
        }
        this.modalRef = this.ms.show(
            formModal,
            {
                class: 'modal-mg modal-dialog-centered back-office-panel',
                backdrop: 'static',
                ignoreBackdropClick: true,
                keyboard: false
            }
        )
    }

    // save(f: any) {

    //     let originalDate = moment(this.dataForm.controls.date.value).format('YYYY-MM-DD');
    //     let originalTime = moment(this.dataForm.controls.time.value).format('hh:mm:ss');
    //     this.loginLoading = true
    //     if (this.dataForm.invalid) {
    //             this.alert.error('Please fill in complete data & then try again')
    //             this.loginLoading = false
    //             return
    //     }
    //     if (this.dataForm.value.password !== this.dataForm.value.confirmPassword) {
    //         this.alert.error('Password does not match')
    //         this.loginLoading = false
    //         return
    //     }
    //     const params = this.dataForm.getRawValue();
    //     params.date = originalDate
    //     params.time = originalTime
    //     console.log(params)
    //     this.ds.add(params).subscribe((resp :any) => {
    //         this.loginLoading = false

    //         if(resp.status == false) {
    //             this.alert.error(resp.errors.general)
    //             return
    //         }else {
    //             this.alert.success('Mentor add successfully!!')
    //         }
    //         f.resetForm()
    //         this.modalRef.hide()
    //         this.ds.getList().subscribe((resp:any) =>{
    //             this.dataStatus = ''
    //             this.inspectionsList = resp.data
    //             console.log(this.inspectionsList)
    //         })
    //     })


    // const catEmail = params.email
    // const catPassword = params.password
    // const catAddress = params.address
    // const catPhoneNumber = params.phoneNumber
    // let saveUpdate = this.ds.add({ email: catEmail, password: catPassword, address: catAddress, phoneNumber: catPhoneNumber})
    // if (this.selectedIndex !== -1) {
    //     saveUpdate = this.ds.update({ id: this.selectedId, title: catTitle, description: catDescription })
    // }

    //         saveUpdate.subscribe((resp: any) => {
    //             this.loginLoading = false
    //             if (resp.success === false) {
    //                 this.alert.error(resp.message)
    //                 this.loginLoading = false


    //             } else if (this.dataForm.value.id !== null) {
    //                 this.alert.success('Changes done successfully!!')
    //                 this.mentorList[this.selectedIndex] = resp.data
    //                 this.dataForm.controls.id.setValue(null)

    //             } else {
    //                 params.id = resp.data.id
    //                 this.alert.success('Category added successfully!!')
    //                 // this.categoriesList.push(resp.data)
    //                 this.search()
    //             }
    //             f.resetForm()
    //             this.modalRef.hide()
    //             this.selectedId = -1

    //         })
    // }

    // get g() {
    //     return this.dataForm.controls
    // }

    // cancelButton(f: any) {
    //     f.resetForm()
    //     this.modalRef.hide()
    //     this.selectedIndex = -1
    // }

    // statusConfirmingModal(changeStatus: TemplateRef<any>, id: number, ind: number, status: string) {
    //     this.selectedId = id
    //     this.selectedIndex = ind
    //     this.selectedStatus = status
    //     this.modalRef = this.ms.show(changeStatus, {
    //         class: 'modal-md modal-dialog-centered back-office-panel',
    //         backdrop: 'static',
    //         ignoreBackdropClick: true,
    //         keyboard: false
    //     })
    // }

    //     changeStatusActive() {
    //         this.waiting.userStatus[this.selectedIndex] = true
    //         const params = {
    //             id: this.selectedId,
    //             //status: this.selectedStatus
    //         }
    //         this.ds.activate(params).subscribe((resp: any) => {
    //             this.waiting.userStatus[this.selectedIndex] = false
    //             if (resp.success === true) {
    //                 this.alert.success('Status Changes Active Successfully')
    //                 this.modalRef.hide()
    //                 this.mentorList[this.selectedIndex].status = this.selectedStatus
    //             } else {
    //                 this.alert.error(resp?.message || '')
    //             }
    //         })
    //     }

    // changeStatusInactive() {
    //     // this.waiting.userStatus[this.selectedIndex] = true
    //     // const params = {
    //     //     id: this.selectedId,
    //         //status: this.selectedStatus
    //     }
    //         this.ds.deactivate(params).subscribe((resp: any) => {
    //             this.waiting.userStatus[this.selectedIndex] = false
    //             if (resp.success === true) {
    //                 this.alert.success('Status Changes Inactive Successfully')
    //                 this.mentorList[this.selectedIndex].status = this.selectedStatus
    //                 this.modalRef.hide()
    //             } else {
    //                 this.alert.error(resp?.message || '')
    //             }
    //         })
    //     }

    //     delete() {
    //         this.waiting.save = true
    //         const param = {
    //             id: this.selectedId
    //         }
    //         this.ds.delete(param).subscribe((resp: Resp<any>) => {
    //             this.waiting.save = false
    //             if (resp.success === false) {
    //                 this.alert.error(resp?.message as string)
    //                 this.modalRef.hide()
    //                 return
    //             }
    //             this.mentorList.splice(this.selectedIndex, 1)
    //             this.modalRef.hide()
    //             this.alert.success('Category Deleted Successfully!')
    //         })
    //     }

    //     confirmDelModal(template: TemplateRef<any>, id: number, index: any) {
    //         this.selectedId = id
    //         this.selectedIndex = index
    //         this.modalRef = this.ms.show(template, {
    //             class: 'modal-sm modal-dialog-centered back-office-panel'
    //         })
    //     }

    // cancelRegionButton() {
    //     this.modalRef.hide()
    // }

    ngOnDestroy(): void {
        // this.searchKeywordSub.unsubscribe()
    }
    // changeStatusInactive() {
    //     this.loginLoading = true
    //     const params = {id : this.selectedId}
    //     this.ds.activate(params).subscribe((resp :any) => {
    //         console.log(resp)
    //         this.loginLoading = false
    //         if(resp.success == false) {
    //             this.alert.error(resp.errors.general)
    //             return
    //         } else {
    //             this.alert.success(resp.msg)
    //             this.mentorList[this.selectedIndex].status = 'active'
    //             this.modalRef.hide()
    //         }
    //     })


    // }
    // changeStatusActive() {
    //     this.loginLoading = true
    //     const params = {id : this.selectedId}
    //     this.ds.deactivate(params).subscribe((resp :any) => {
    //         console.log(resp)
    //         this.loginLoading = false
    //         if(resp.success == false) {
    //             this.alert.error(resp.errors.general)
    //             return
    //         } else {
    //             this.alert.success(resp.msg)
    //             this.mentorList[this.selectedIndex].status = 'inactive'
    //             this.modalRef.hide()
    //         }
    //     })

    // }
    // delete() {
    //     this.waiting.save = true
    //     const params = { id : this.selectedId}
    //     this.ds.delete(params).subscribe((resp :any) => {
    //         this.waiting.save = false
    //         if(resp.success == false) {
    //             this.alert.error('Not Deleted')
    //             return
    //         } 
    //         this.alert.success(resp.msg)
    //         this.mentorList.splice(this.selectedIndex,1);
    //         this.modalRef.hide()
    //     })

    // }
}
