import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { LoaderOptionsObject } from 'src/app/interfaces/loaderOptions'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { Pagination, Resp } from 'src/app/interfaces/response'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from './data.service'
import { apis } from 'src/environments/environment'
import moment from 'moment'


@Component({
    selector: 'app-inspection',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.css']
})
export class InspectionComponent implements OnDestroy, OnInit {
    dataStatus = 'fetching'
    currentDate = new Date()
    baseUrl: any
    statusTitle: any
    mytime: Date = new Date();
    inspectionsList: Array<any> = []
    selectedIndex: number
    modalRef: BsModalRef
    status: any
    pagination: Pagination<any>
    searchKeyword = ''
    startDate:any =""
    endDate:any =""
    selectedStatus = ''
    searchKeyword$: Subject<string> = new Subject<string>()
    searchKeywordSub: any
    modalTitle = ''
    moment = moment
    loginLoading = false
    dataForm: FormGroup
    requestDetail: any
    selectedId: any
    class1 = 'ASC arrow'
    class2 = 'DESC arrow active'
    cities = ["Karachi", "Lahore", "Islamabad", "Rawalpindi", "Faisalabad", "Multan", "Gujranwala", "Sialkot", "Peshawar", "Quetta", "Sukkur", "Hyderabad", "Gujrat", "Bahawalpur", "Sargodha", "Abbottabad", "Mardan", "Larkana", "Sahiwal", "Mirpur Khas", "Sheikhupura", "Jhang", "Rahim Yar Khan", "Okara",];
    d: any
    i: any
    filters: any = {
        page: 1,
        per_page: 10,
        city: '',
        startDate: '',
        endDate: '',
        keyword: ''
    }
    breadCrum = [
        {
            link: '/mentor/inspections',
            value: 'Inspections'
        }
    ]
    loaderOptions: LoaderOptionsObject = ConstantsService.createLoaderConfig(5, 5, 1)


    waiting: {
        search: boolean
        save: boolean
        userStatus: Array<any>
    }

    constructor(
        private ds: DataService,
        private fb: FormBuilder,
        public ui: UIHelpers,
        private ms: BsModalService,
        private alert: IAlertService,
        public router: Router,
        private route: ActivatedRoute,
        public cs: ConstantsService,
    ) {
        this.baseUrl = apis.baseUrl
        this.waiting = {
            search: false,
            save: false,
            userStatus: []
        }


        this.dataForm = this.fb.group({
            description: new FormControl(null, [Validators.maxLength(500)]),
            date: new FormControl(null, [Validators.required]),
            startTime: new FormControl(null, [Validators.required]),
            //endTime: new FormControl(null, [Validators.required]),
            maxBookings: new FormControl('3', [Validators.required]),
            address: new FormControl(null, [Validators.required]),
            addressTwo: new FormControl(null),
            //house:new FormControl(null),
            //street: new FormControl(null),
            city: new FormControl(null, [Validators.required]),
            //state: new FormControl(null, [Validators.required]),
            zipCode: new FormControl(null, [Validators.required]),

        })

        this.route.queryParams.subscribe((params) => {
            if (params.page) {
                this.filters.page = params.page
            }
            if (params.perPage) {
                this.filters.per_page = params.perPage
            }
            if (params) {
                this.search()
            }
        })
    }

    ngOnInit(): void {
        this.searchKeywordSub = this.searchKeyword$
            .pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((searchKeyword) => {
                this.filters.page = 1
                this.search()
            })
    }
    setPagination(page: any) {
        this.filters.page = page
        this.router.navigate(['/mentor/inspections'], {
            queryParams: this.filters,
            replaceUrl: true
        })
    }

    searchKeywordChange(value: string) {
        this.searchKeyword$.next(value)
    }

    search(): void {
        let startDate
        let endDate
        
        if (this.startDate != '') {
           
            this.filters.startDate = moment(this.startDate).format('YYYY-MM-DD');
            
        }

        if (this.endDate != '') {
           
           
            this.filters.endDate = moment(this.endDate).format('YYYY-MM-DD');
        }
        
        this.waiting.search = true
        this.ds.getList(this.filters).subscribe((resp: any) => {
            this.waiting.search = false
            if (resp.success === true) {
                this.inspectionsList = resp.data.data || []
                this.pagination = resp.data as Pagination<any>
                this.dataStatus = 'done'
            }
        })
        // if (this.filters.startDate != '' && this.filters.endDate != '') {
        //     this.filters.startDate = startDate
        //     this.filters.endDate = endDate
        // }

    }
    clear() {
        this.filters.page = 1;
        this.filters.keyword = '';
        this.filters.startDate = '';
        this.filters.endDate = '';
        this.filters.city = '';
        this.startDate='';
        this.endDate ='';
        this.search()
    }
    openModalStatus(formModal: any, id: number, index: number, action: any) {
        if (action == 'inProgress') {
            this.statusTitle = 'In Progress'
        } else if (action == 'canceled') {
            this.statusTitle = 'Cancelled'
        } else {
            this.statusTitle = 'Completed'
        }
        this.modalRef = this.ms.show(
            formModal,
            {
                class: 'modal-mg modal-dialog-centered back-office-panel',
                backdrop: 'static',
                ignoreBackdropClick: true,
                keyboard: false
            }
        )
        this.selectedId = id
        this.selectedIndex = index
        this.status = action
        // api/mentor/inspections/update-status'id'
        // =>
        // 'required|integer', 
        //  'status' => 'required|in:inProgress,canceled,completed'
    }
    openModal(formModal: any, id: number, index: number) {
        this.modalTitle = 'Add New Inspection'
        this.selectedIndex = index
        if(index == -1){
            this.dataForm.patchValue({maxBookings:3}) 
        }
        if (index > -1) {
            this.modalTitle = 'Edit Inspection'
            this.selectedId = id
            this.selectedIndex = index
        }
        this.modalRef = this.ms.show(
            formModal,
            {
                class: 'modal-lg modal-dialog-centered back-office-panel',
                backdrop: 'static',
                ignoreBackdropClick: true,
                keyboard: false
            }
        )
        this.requestDetail = this.inspectionsList[this.selectedIndex]

        if (formModal._declarationTContainer.localNames[0] == 'editForm') {

            this.dataForm.patchValue(this.inspectionsList[this.selectedIndex])
            // this.dataForm.controls.date.reset()
            // this.dataForm.controls.startTime.reset()
            let date = new Date(this.inspectionsList[this.selectedIndex].date)
            this.dataForm.controls.date.setValue(date);
            const start: any = this.inspectionsList[this.selectedIndex].startTime.split(':');
            const startTime = new Date();
            startTime.setHours(start[0]);
            startTime.setMinutes(+start[1])
            startTime.setSeconds(0)
            this.dataForm.controls.startTime.setValue(startTime)
            const end:any=this.inspectionsList[this.selectedIndex].endTime.split(':');
            const endTime = new Date();
            endTime.setHours(end[0]);
            endTime.setMinutes(+end[1])
            endTime.setSeconds(0)
            this.dataForm.controls.endTime.setValue(endTime)
        }

    }
    changeStatusAction() {
        this.loginLoading = true
        const params = {
            id: this.selectedId,
            status: this.status
        }
        this.ds.changeStatus(params).subscribe((resp: any) => {
            console.log(resp)
            this.loginLoading = false
            if (resp.success == false) {
                this.alert.error(resp.errors.general)
                return
            } else {
                this.alert.success(resp.msg)
                this.inspectionsList[this.selectedIndex].status = this.status
                this.modalRef.hide()
            }
        })
    }

    doSortDate(order: any) {
        // this.filters.orderBy = orderBy

        if(order == 'ASC'){
            this.class1 = 'ASC arrow active'
            this.class2 = 'DESC arrow'
        }

        if(order == 'DESC'){
            this.class1 = 'ASC arrow'
            this.class2 = 'DESC arrow active'
        }
        this.filters.order = order
        this.search()
    }
    save(f: any) {

        let originalDate = moment(this.dataForm.controls.date.value).format('YYYY-MM-DD');
       let sTime =  moment(this.dataForm.controls.startTime.value);
        let timeNow = moment();

        if(sTime < timeNow){
            this.alert.error('Start time cannot be less than time now')
            this.loginLoading = false
            return
        }
    

        let startTime = moment(this.dataForm.controls.startTime.value).format('HH:mm:ss');
        let endTime = moment(this.dataForm.controls.startTime.value).add(2,'hours').format('HH:mm:ss')
        //let endTime = moment(this.dataForm.controls.endTime.value).format('HH:mm:ss');
        if(startTime > endTime){
            this.alert.error('Start time cannot be greater than end time')
            this.loginLoading = false
            return
        }
        this.loginLoading = true
        if (this.dataForm.invalid) {
            this.alert.error('Please fill in complete data & then try again')
            this.loginLoading = false
            return
        }
        if (this.dataForm.value.password !== this.dataForm.value.confirmPassword) {
            this.alert.error('Password does not match')
            this.loginLoading = false
            return
        }
        const params = this.dataForm.getRawValue();
        params.date = originalDate
        params.startTime = startTime
        params.endTime = endTime
        this.ds.add(params).subscribe((resp: any) => {
            this.loginLoading = false

            if (resp.success == false) {
                this.alert.error(resp.errors.general)
                return
            } else {
                this.alert.success('Inspection add successfully!!')
            }
            f.resetForm()
            this.modalRef.hide()
            this.search()
        })
    }
    edit(f: any) {

        let originalDate = moment(this.dataForm.controls.date.value).format('YYYY-MM-DD');
        let startTime = moment(this.dataForm.controls.startTime.value).format('HH:mm:ss');
        let endTime = moment(this.dataForm.controls.startTime.value).add(2,'hours').format('HH:mm:ss')
        //let endTime = moment(this.dataForm.controls.endTime.value).format('HH:mm:ss');
        if(startTime > endTime){
            this.alert.error('Start time cannot be greater than end time')
            this.loginLoading = false
            return
        }
        this.loginLoading = true
        if (this.dataForm.invalid) {
            this.alert.error('Please fill in complete data & then try again')
            this.loginLoading = false
            return
        }
        if (this.dataForm.value.password !== this.dataForm.value.confirmPassword) {
            this.alert.error('Password does not match')
            this.loginLoading = false
            return
        }
        const params = this.dataForm.getRawValue();
        params.date = originalDate
        params.startTime = startTime
        params.endTime = endTime
        console.log(params)
        params.id = this.selectedId
        this.ds.update(params).subscribe((resp: any) => {
            this.loginLoading = false
            if (resp.success == false) {
                this.alert.error(resp.errors.general)
                return
            } else {
                this.alert.success('Inspection Edit successfully!!')
            }
            f.resetForm()
            this.modalRef.hide()
            this.search()
        })

    }
    get g() {
        return this.dataForm.controls
    }

    cancelButton(f: any) {
        f.resetForm()
        this.modalRef.hide()
        this.selectedIndex = -1
    }

    statusConfirmingModal(changeStatus: TemplateRef<any>, id: number, ind: number, status: string) {
        this.selectedId = id
        this.selectedIndex = ind
        this.selectedStatus = status
        this.modalRef = this.ms.show(changeStatus, {
            class: 'modal-md modal-dialog-centered back-office-panel',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }

    cancelRegionButton() {
        this.modalRef.hide()
    }

    ngOnDestroy(): void {
        // this.searchKeywordSub.unsubscribe()
    }
}
